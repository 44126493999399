import React, { useState, useEffect } from "react";
import { Rept, sto } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
import axios from "axios";
import { uri } from "../../../../help/Api";
import { useHeader, useBody } from "../../../Invs/bill-template02/style";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";

function HeaderA(props) {

  const {info,found }=props;
  const cls = useRptAF();
  const styf = { fontWeight: "1200" };
  const stysub = { fontWeight: "bold" };
  const styfC = { fontWeight: "500" };

  const calssBody = useBody();
  const classHeader = useHeader();
  const clasm = useInvMain();
  const clas = useInvAF();

  return (
    <Grid item container
      justifyContent="center"
      style={{
        paddingTop: "1rem", fontWeight: "bold", fontFamily: "Amiri-Regular",
        // Width: "20.45m"
      }}>
      {found.header_img != null ? (
        (
          <Grid item xs={12} justifyContent="center" style={{ minWidth: "20cm", maxWidth: "20cm", minHeight: "3.6cm", maxHeight: "3.6cm", paddingBottom: "0.2cm", paddingTop: "0.1cm" }}>
            <div>
              <ButtonBase>
                {" "}
                <img
                  src={`data:image/jpeg;base64,${found.header_img}`}
                  className={clas.headerImg}
                  alt={found.nameA}
                />{" "}
              </ButtonBase>
            </div>
          </Grid>
        )
      ) : (

        <Grid
          item
          container

          style={{ fontWeight: "bold", border: "solid 1px", borderRadius: "5px", fontFamily: "Amiri-Regular", marginLeft: "0cm" }}
        >
          <Grid item xs={5} className={classHeader.leftHeaderen}>
            <div style={styf}>{info.Disc1_header_en}</div>
            <div style={stysub}>{info.Disc2_header_en}</div>
            <div style={styfC}>{info.Disc3_header_en}</div>{" "}
          </Grid>


          <Grid item xs={2} className={clasm.mincntr}>
            <div>
              <ButtonBase>
                {" "}
                <img
                  src={`data:image/jpeg;base64,${info.Logo}`}
                  className={clas.Hedlogo}
                  alt={info.nameA}
                />{" "}
              </ButtonBase>
            </div>
          </Grid>


          <Grid item xs={5} className={classHeader.rightHeaderar}>
            <div style={styf}>{info.Disc1_header_ar}</div>{" "}
            <div style={stysub}>{info.Disc2_header_ar}</div>
            <div style={styfC}>{info.Disc3_header_ar}</div>{" "}
          </Grid>
        </Grid>
      )}


      <Grid item container style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
        <Grid item xs={12} style={{
          fontFamily: "Amiri-Regular", border: "solid 2px", paddingTop: "0.5rem", paddingBottom: "0.5rem",
          borderRadius: "5px", textAlign: "center",
        }}
        >
          <div style={styf}>
            {Rept.title}
          </div>
        </Grid>
      </Grid>
      <Grid item container style={{
        fontFamily: "Amiri-Regular", padding: "0.3rem", border: "solid 2px",
        borderRadius: "5px", direction: "rtl",
      }}
      >
        <Grid item xs={12} container>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            {Rept.from} /{" "} {Rept.account.name}
          </Grid>
          <Grid item xs={3}>
            <div style={styfC}>{"من تاريخ"}{"    /"} {Rept.fDate}</div>
          </Grid>
          <Grid item xs={3}>
            <div style={styfC}> {"إلى تاريخ"}{"     /"} {Rept.tDate}</div>
          </Grid>
        </Grid>
        <Grid item xs={12} container style={{ textAlign: "right", paddingTop: "0.3rem" }}>
          <Grid item xs={2}>
            {"المستخدم"}  {"   /"}  {"------"}
          </Grid>
          {/* <Grid item xs={3}>
            {"------"}
          </Grid> */}
          <Grid item xs={4}>
            {"تاريخ الطباعة"} {"    /"} {Rept.nDate}
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderA;