import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableBody, MTableBodyRow } from "material-table";
// Ust
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../../St/Reviews/cStyle";
import Paper from "@material-ui/core/Paper";
import { getTotalAmount } from "../../../../utils/utils";
import { useBody } from "../../invCompany/style";
import tafqeet from "../../../../utils/tafqeet";
import { TextField } from "@material-ui/core";

const InvTotalsEn = (porps) => {

    const { found, inv } = porps;

    const clasm = useInvMain();
    const clas = useInvAF();
    const calssBody = useBody();
    const locInv = JSON.parse(localStorage.getItem("datainv")) || {};

    const [sta, setSta] = useState({ info: [], tot: {} });
    const [showSc, setShowSc] = useState(true);
    const styfC = { fontWeight: "1300" };

    const totVAT=sta.tot.amountOfVat;
const totNet=sta.tot.tot_net;
const totBef=getTotalAmount(sta.tot) - sta.tot?.discount;

const totVatN=new Intl.NumberFormat('en-US',{
  minimumFractionDigits:2,
  maximumFractionDigits:2,
}).format(totVAT);

const totNetN=new Intl.NumberFormat('en-US',{
  minimumFractionDigits:2,
  maximumFractionDigits:2,
}).format(totNet);

const totBefN=new Intl.NumberFormat('en-US',{
  minimumFractionDigits:2,
  maximumFractionDigits:2,
}).format(totBef);

    const getInfo = async () => {
        // alert('getInfo')
        const tb = {
            Rpt_Inv: true,
            wait: true,
            parms: {
                lprocid: locInv.proc_id,
                ibranchid: locInv.branch,
                iFoundid: 1,
                sProcTypeInvProc: locInv.ProcTypeInvProc,
            },
            autos: {},
        };

        await axios
            .post(`${uri}Res`, tb)
            .then((res) => {
                setSta({ info: res.data.RptInv, tot: res.data.infoBill });
                console.log("res.data.RptInv");
                console.log(res.data);
                setShowSc(res.data.wait.state);
            })
            .catch((error) => {
                console.log("catch");
            });
    };

    useEffect(() => {
        // alert('getInfo')

        getInfo();
    }, []);
    return (
        <Grid
            item
            container
            // className={clas.BdytotSub}
            style={{ fontFamily: "Amiri-Regular", }}
        // className={calssBody.BdymtHeaderTopp}
        >
            <Grid
                item
                container
                xs={12}
                style={{ fontFamily: "Amiri-Regular", }}
            >

                <Grid item xs={8} className={clas.BdyborBtRTOPTotal} >
                    <div style={{ fontWeight: "bold", padding: 3 }}>{"Net Amount"} {"الصافي"}</div>
                </Grid>
                <Grid item xs={4} className={clas.BdyborBtRTOPTotal} style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: "bold", padding: 3 }}>{"SAR   "}{totBefN}</div>
                </Grid>
                <Grid item xs={8} className={clas.BdyborBtRTOPTotal2}>
                    <div style={{ fontWeight: "bold", padding: 3 }}>{"VAT 15%"} {"ضريبة القيمة المضافة"}</div>

                </Grid>
                <Grid item xs={4} className={clas.BdyborBtRTOPTotal2} style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: "bold", padding: 3 }}>{"SAR   "}{totVatN} </div>

                </Grid>

                <Grid item xs={8} className={clas.BdyborBtRTOPTotal2}>
                    <div style={{ fontWeight: "bold", padding: 3 }}>{"Grand Total"} {"الإجمالي"}</div>

                </Grid>
                <Grid item xs={4} className={clas.BdyborBtRTOPTotal2} style={{ textAlign: "center" }}>
                    <div style={{ fontWeight: "bold", padding: 3 }}>{"SAR   "}{totNetN} </div>

                </Grid>
                {/* <Grid item xs={8} className={clas.BdyborBtRTOPTotal2}>
        <div  style={{fontWeight:"bold", padding:3}}>{"Rounded Total"} {"الإجمالي"}</div>

        </Grid>
        <Grid item xs={4} className={clas.BdyborBtRTOPTotal2} style={{ textAlign: "center" }}>
          <div  style={{fontWeight:"bold", padding:3}}>{"SAR   "}{sta.tot.tot_net} </div>

        </Grid> */}
                <Grid item xs={12} className={clas.BdyborBtRTOPTotal2}>

                    <div style={{ fontWeight: "bold", padding: 3 }}>{tafqeet(sta.tot.tot_net, "SAR")} </div>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={12}
                style={{
                    fontFamily: "Amiri-Regular", paddingTop: "0.5rem",
                }}
            >
                <Grid item xs={12} style={{ textAlign: "left", border: "solid 0.1px", }}>
                    {/* <div>{found.Disc1_footer_en}</div>
          <div>{found.Disc2_footer_en}</div>
          <div>{found.Disc3_footer_en}</div>
          <div>{found.Disc4_footer_en}</div> */}
                    <TextField fullWidth variant="outlined" size="small"
                        id="refno"
                        //className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                        inputProps={{ borderColor: "black", color:'red',fontWeight:"bold",fontSize:"0.9rem",fontFamily: "Amiri-Regular" }}
                        type="text"
                        name="notes"
                        multiline
                        value={inv.notes}
                    />
                    {/* <div aria-multiline style={{ fontWeight: "bold", fontSize: "0.8rem" }}> {inv.notes}     </div> */}
                </Grid>

            </Grid>
            {locInv.ProcTypeInvProc != "03" && locInv.ProcTypeInvProc != "04" ?(
                            <Grid item xs={12} container className={clas.footerB} >


                            <Grid item xs={4} style={{ fontFamily: "Amiri-Regular", fontWeight: "bold", paddingTop: "1rem", textAlign: "center" }}>
                                {" "}
                                {"SALES"}
                            </Grid>
                            <Grid item xs={4} style={{ fontFamily: "Amiri-Regular", fontWeight: "bold", paddingTop: "1rem", textAlign: "center" }}>
                                {"FINANCIAL"}
                            </Grid>
            
                            <Grid item xs={4} style={{ fontFamily: "Amiri-Regular", fontWeight: "bold", paddingTop: "1rem", textAlign: "center" }}>
                                {"CUSTOMER"}
                            </Grid>
                        </Grid>
            ):null}
        </Grid>
    );
};

export default InvTotalsEn;
