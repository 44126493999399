import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { sto } from "../../../../Context/InshData";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
function FooterD(porps) {

  const { found, inv, sta } = porps;
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  const clas = useInvAF();
  const sty = {
    cntr: { textAlign: "center", paddingTop: 10 },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: {
      direction: "rtl",
      color: "black",
      // position: "relative",
      // style={{ }}
      // position: "fixed",
    },
    dirL: { direction: "ltr" },
  };
  return (
    <Grid item container style={sty.dirR}>
      <Grid item container style={sty.cntr} spacing={0}>


      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={1}
        style={{ paddingTop: 10, fontWeight: "bold", textAlign: "center" }}
      >
        <Grid item xs={12} style={{ fontFamily: "Amiri-Regular" }}>


          {found.footer_img == null ? (
            <Grid item xs={12} container className={clas.footerA}>

              <Grid item xs={6} className={clas.BdytotCntMmm}>
                {inv.notes}
              </Grid>
              <Grid item xs={6} className={clas.BdytotCntMmm}>
                {found.BillNotes}
              </Grid>
              <br />

            </Grid>
          ) : (
            (<Grid item xs={12} justifyContent="center" style={{ minWidth: "20cm", maxWidth: "20cm", minHeight: "3.6cm", maxHeight: "3.6cm", paddingBottom: "0.2cm" }}>
              <div>
                <ButtonBase>
                  {" "}
                  <img
                    src={`data:image/jpeg;base64,${found.footer_img}`}
                    className={clas.headerImg}
                    alt={found.nameA}
                  />{" "}
                </ButtonBase>
              </div>
            </Grid>)
          )}
          {premiApp.billTemplate !== 11 && (
            <Grid item xs={12} container className={clas.footerB}>
              <Grid item xs={2} style={{ fontFamily: "Amiri-Regular" }}>
                {sto.usernames}
              </Grid>
              <Grid item xs={2}>
                {locDinv?.salesman}
              </Grid>
              <Grid item xs={4} style={{ fontFamily: "Amiri-Regular" }}>
                {" "}
                {"  البائع" + "     " + " Salesmans Sign "}
              </Grid>
              <Grid item xs={4} style={{ fontFamily: "Amiri-Regular" }}>
                {"توقيع المستلم"  + "     " + " Receivrs Sign "}
              </Grid>
            </Grid>)}

        </Grid>

      </Grid>
    </Grid>
  );
}
export default FooterD;

