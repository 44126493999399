import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { sto } from "../../../../Context/InshData";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import { useBody, useHeader } from "../../invCompany/style";
import { TextField } from "@material-ui/core";
function FooterDev(props) {
    const { data, InvForFatora, found } = props;
    const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};
    const clas = useInvAF();
    const classHeader = useHeader();

    const clasm = useInvMain();

    const styf = { fontWeight: "bold", fontSize: "0.7rem", };
    const styfE = { fontWeight: "bold", fontSize: "0.55rem", };
    const styfC = { fontSize: "1rem", fontWeight: "bold", fontFamily: "Amiri-Regular", paddingBottom: "0.3rem" };

    const sty = {
        cntr: { textAlign: "center", paddingTop: 10 },
        lft: { textAlign: "left", padingLeft: 5 },
        rit: { textAlign: "right", padingRight: 5 },
        dirR: {
            direction: "rtl",
            color: "black",
        },
        dirL: { direction: "ltr" },
    };

    return (
        <Grid item container

            style={{ padding: 5, fontWeight: "bold", fontFamily: "Amiri-Regular", marginLeft: "0cm",fontFamily: "Amiri-Regular" }}
        >
            <Grid
                item
                container
                xs={12}
                style={{ fontWeight: "bold", }}
            >
                <Grid item xs={12} container  >
                    <Grid item xs={12} className={clasm.minrit} style={{ direction: "ltr", textAlign: "left", paddingTop: "0.5rem" }}>
                        <div style={styfC}>{"Terms, Condations & Other Information"}  {sto.usernames}</div>
                    </Grid>
                    <Grid item xs={12} className={clasm.minleft}>
                        {/* <div style={styfC}>{found.Disc1_footer_en}</div>
                        <div style={styfE}>{found.Disc2_footer_en}</div>
                        <div style={styfE}>{found.Disc3_footer_en}</div>
                        <div style={styfE}>{found.Disc4_footer_en}</div> */}
                        <TextField fullWidth variant="outlined" size="small"
                            id="refno"
                            //className={isPurches ? clasTF.rootPurch : isStock ? clasTF.rootPurch : clasTF.rootSale}
                            inputProps={{ borderColor: "black", color: 'red', fontWeight: "bold", fontSize: "0.9rem",fontFamily: "Amiri-Regular" }}
                            type="text"
                            name="notes"
                            multiline
                            value={InvForFatora.notes}
                        />
                        {/* <div>{InvForFatora.notes}  </div> */}
                    </Grid>

                    {/* <Grid item xs={6} className={clasm.minrit}>
                        <div style={styfC}>{found.Disc1_footer_ar}</div>
                        <div style={styf}>{found.Disc2_footer_ar}</div>
                        <div style={styf}>{found.Disc3_footer_ar}</div>
                        <div style={styf}>{found.Disc4_footer_ar}</div>

                    </Grid> */}
                    <br />
                    <Grid item xs={12} container style={{ paddingTop: "1rem" }} >
                        <Grid item xs={6} container  >

                            <Grid item xs={12} className={clasm.minrit} style={{ direction: "ltr", textAlign: "left", paddingTop: "0.5rem" }}>
                                <div style={styfC}>{"Received and Accepted"}</div>
                            </Grid>
                            <Grid item xs={12} style={{ fontFamily: "Amiri-Regular", paddingTop: "0.3rem" }}>
                                {"Name :"}
                            </Grid>
                            <Grid item xs={12} style={{ fontFamily: "Amiri-Regular", paddingTop: "0.3rem" }}>
                                {"Signature :"}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container  >

                            <Grid item xs={12} className={clasm.minrit} style={{ direction: "ltr", textAlign: "left", paddingTop: "0.5rem" }}>
                                <div style={styfC}>{"Transporter Name : "}  {sto.usernames}</div>
                            </Grid>
                            <Grid item xs={12} style={{ fontFamily: "Amiri-Regular", paddingTop: "0.3rem" }}>
                                {"Vehicle No :"}
                            </Grid>
                            <Grid item xs={12} style={{ fontFamily: "Amiri-Regular", paddingTop: "0.3rem" }}>
                                {"Signature :"}
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>

    );
}
export default FooterDev;

