import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../../Label/Lbl";
import BackdropScI from "../../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import MaterialTable, { MTableBody, MTableBodyRow } from "material-table";
// Ust
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../../St/Reviews/cStyle";
import Paper from "@material-ui/core/Paper";
import { getTotalAmount } from "../../../../utils/utils";
import { useBody } from "../../invCompany/style";
import { Directions } from "@material-ui/icons";

const InvTotal = () => {
  const clasm = useInvMain();
  const clas = useInvAF();
  const calssBody = useBody();
  const locInv = JSON.parse(localStorage.getItem("datainv")) || {};
  const premiApp = JSON.parse(localStorage.getItem("premiApp")) || {};

  const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);
  const styfC = { fontWeight: "1300" };
  const styfCA = { fontWeight: "1300", direction: "rtl", textAlign: "right" };

  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      Rpt_Inv: true,
      wait: true,
      parms: {
        lprocid: locInv.proc_id,
        ibranchid: locInv.branch,
        iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    };

    await axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log("res.data.RptInv");
        console.log(res.data);
        setShowSc(res.data.wait.state);
      })
      .catch((error) => {
        console.log("catch");
      });
  };

  useEffect(() => {
    // alert('getInfo')

    getInfo();
  }, []);
  return (
    <Grid
      item
      container
      // className={clas.BdytotSub}
      style={{ fontFamily: "Amiri-Regular" }}
    // className={calssBody.BdymtHeaderTopp}
    >

      <Grid item xs={5} className={clas.BdyborBtRTOPTotal}>

        <div style={styfCA}>  {"الإجمالي"} {"(غير شامل الضريبة القيمة المضافة)"}</div>{" "}
      </Grid>
      <Grid item xs={5} className={clas.BdyborBtRTOPTotal}>
        {txtE.amountWithVat}
      </Grid>
      <Grid item xs={2} className={clas.BdyborBtRTOPTotal} style={{ textAlign: "center" }}>
        {getTotalAmount(sta.tot)}
      </Grid>
      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2} >
        <div style={styfCA}>   {txtA.discount}</div>{" "}
      </Grid>
      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
        {txtE.discount}
      </Grid>
      <Grid item xs={2} className={clas.BdyborBtRTOPTotal2} style={{ textAlign: "center" }}>
        {sta.tot.discount}
      </Grid>

      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>

        <div style={styfCA}>  {"الاجمالي بعد الخصم"}</div>{" "}
      </Grid>
      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
        {"Amount After Discount"}
      </Grid>
      <Grid item xs={2} className={clas.BdyborBtRTOPTotal2} style={{ textAlign: "center" }}>
        {getTotalAmount(sta.tot) - sta.tot?.discount}
      </Grid>

      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2} direction="rtl">

        <div style={styfCA}> {" مجموع ضريبة القيمة المضافة"}{"  15%"}</div>{" "}
      </Grid>
      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
        {txtE.amountOfVat}
      </Grid>
      <Grid item xs={2} className={clas.BdyborBtRTOPTotal2} style={{ textAlign: "center" }}>
        {sta.tot.amountOfVat}
      </Grid>

      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>

        <div style={styfC}>{txtA.tot_net}</div>{" "}
      </Grid>
      <Grid item xs={5} className={clas.BdyborBtRTOPTotal2}>
        {txtE.tot_net}
      </Grid>
      <Grid item xs={2} className={clas.BdyborBtRTOPTotal2} style={{ textAlign: "center" }}>
        {sta.tot.tot_net}
      </Grid>
      {premiApp.billTemplate === 13 && (
        <>
          <Grid item xs={6} className={clas.BdyborBtRTOPTotal2}>
            {"اجمالي المدفوع"}    {"     :"}  {sta?.tot?.inv_type_name == "نقدية"
            ? sta?.tot?.tot_net?.toFixed(2)
            : sta?.tot?.paid}
          </Grid>
          <Grid item xs={6} className={clas.BdyborBtRTOPTotal2}>
          {"اجمالي المثبفي"}     {"     :"}            {sta.tot?.inv_type_name == "نقدية"
            ? 0
            : sta?.tot?.tot_net?.toFixed(2) - sta?.tot?.paid }
          </Grid>
        </>
      )}

    </Grid>
  );
};

export default InvTotal;
