import React, { useState, useEffect } from "react";
import axios from "axios";
import { uri } from "../../../../help/Api";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../../St/Reviews/UseStyle";
import { sto } from "../../../../Context/InshData";
import { useBody, useHeader } from "../../invCompany/style";
import { txtA } from "../../../../Label/Lbl";
import { Container, Divider, Paper } from "@material-ui/core";
import moment from "moment";

function HeaderDev(porps) {
    const {found, sta,locInv}=porps;

    // const clasH = useInvHeadrAF();
    const classHeader = useHeader();
    const [info, setInfo] = useState({});

    const styff = { fontWeight: "bold", color: "#000" };
    const styfCC = { fontWeight: "bold", color: "#000" };

    const styf = { fontWeight: "bold", fontSize: 18 };
    const styfC = { fontWeight: "500", color: "#9a3f47" };
    const clasm = useInvMain();
    const clas = useInvAF();
    const calssBody = useBody();
    return (
        <Grid
            item
            container
            // className={classHeader.headerContainer}
            style={{
                fontWeight: "bold",
                fontFamily: "Amiri-Regular"
            }}
        >
            <Grid item container >
                <Grid item container >
                    <Grid item xs={5} className={clasm.minleft}>
                        <div style={styff}>{found.Disc1_header_en}</div>
                        <div style={styff}>{found.Disc2_header_en}</div>
                        <div style={styfCC}>{found.Disc3_header_en}</div>{" "}
                        <div style={styfCC}>{found.Disc4_header_en}</div>
                    </Grid>

                    <Grid item xs={2} className={clasm.mincntr}>
                        <div>
                            <ButtonBase>
                                {" "}
                                <img
                                    src={`data:image/jpeg;base64,${found.Logo}`}
                                    className={clas.Hedlogo}
                                    alt={found.nameA}
                                />{" "}
                            </ButtonBase>
                        </div>
                    </Grid>

                    <Grid item xs={5} className={clasm.minrit}>
                        <div style={styff}>{found.Disc1_header_ar}</div>{" "}
                        <div style={styff}>{found.Disc2_header_ar}</div>
                        <div style={styfCC}>{found.Disc3_header_ar}</div>{" "}
                        <div style={styfCC}>{found.Disc4_header_ar}</div>
                    </Grid>
                </Grid>
                <Grid item xs={12} >

                    <Divider style={{ background: "#d3d3d3d", height: 1.5, marginTop: "0.5rem" }} />
                    <Grid style={{ paddingTop: "0.1cm" }}>
                        <Divider
                            style={{ background: "#d3d3d3d", height: 1.5, marginBottom: "0.5rem" }}
                        />
                    </Grid>
                    <br />
                </Grid>

            </Grid>

            <Grid item container >
                <Grid item xs={12} >
                    <div style={styf}> { locInv.ProcTypeInvProc === "02" || locInv.ProcTypeInvProc === "12" || locInv.ProcTypeInvProc === "03"  ?   "DELIVERY NOTE": "PURCHASE RECEIPT"}</div>
                    <div style={styf}>{locInv.ProcTypeInvProc === "02" || locInv.ProcTypeInvProc === "12" || locInv.ProcTypeInvProc === "03"  ? "DN-" :"GRN-"}{sta.tot.id}</div>
                </Grid>
                <Grid item xs={12} >

                    <Divider style={{ background: "#d3d3d3d", height: 1.5 }} />
                    <Grid style={{ paddingTop: "0.1cm" }}>
                        <Divider
                            style={{ background: "#d3d3d3d", height: 1.5 }}
                        />
                    </Grid>
                </Grid>
                <Grid item container style={{ direction: "ltr", paddingTop: "0.5rem" }}>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={12}/* className={calssBody.BdymtHeader} */
                    >
                        <Grid item container>
                            <Grid item container xs={12}>
                                <Grid item container xs={12} style={{ border: "solid 0.1px", padding: "0.6rem" }} >
                                    <Grid item xs={2} style={{ textAlign: "left", paddingRight: 7,fontFamily: "Amiri-Regular" }}>
                                        {"Customer Name"}
                                    </Grid>
                                    <Grid item xs={4}  >
                                        <Paper className={clasm.minclr}>{sta.tot.cust_name}</Paper>
                                    </Grid>
                                    <Grid item xs={2} tyle={{ textAlign: "left", paddingLeft: 7 ,fontFamily: "Amiri-Regular"}}>
                                        {"D.N Date :"}

                                    </Grid>
                                    <Grid item xs={4} >
                                        <Paper className={clasm.minclr}>
                                            {moment(sta.tot.date).format("YYYY/MM/DD")}
                                        </Paper>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item container xs={12} style={{ border: "solid 0.1px", padding: "0.6rem" }} >
                                <Grid item xs={2} style={{ textAlign: "left", paddingRight: 7,fontFamily: "Amiri-Regular" }}>
                                    {"Contact"}
                                </Grid>
                                <Grid item xs={4}  >
                                    <Paper className={clasm.minclr}></Paper>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "left", paddingLeft: 7,fontFamily: "Amiri-Regular" }}>
                                    {/* {txtE.invNumr} */}
                                    {"Shipping Address"}

                                </Grid>
                                <Grid item xs={4} >
                                    <Paper className={clasm.minclr}>{sta.tot.cust_address}</Paper>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} style={{ border: "solid 0.1px", padding: "0.6rem" }} >
                                <Grid item xs={3} style={{ textAlign: "left", paddingRight: 7,fontFamily: "Amiri-Regular" }}>
                                    {"Customers Tax Number"}
                                </Grid>
                                <Grid item xs={4}  >
                                    <Paper className={clasm.minclr}>{sta.tot.tax_no_cust}</Paper>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "left", paddingLeft: 7 ,fontFamily: "Amiri-Regular"}}>
                                    {/* {txtE.invNumr} */}
                                    {"Shipping Address"}

                                </Grid>
                                <Grid item xs={4} style={{ textAlign: "left" }} >
                                    <Paper className={clasm.minclr} style={{ textAlign: "left" }}>{sta.tot.cust_address}</Paper>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "left", paddingRight: 7, fontWeight: "bold", fontSize: "1.5rem", direction: "ltr",fontFamily: "Amiri-Regular" }}>
                                {"Items"}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default HeaderDev;
