import React  from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
// Ust
import { useInvAF } from "../../../../St/Reviews/UseStyle";
// csG
import { stElemnt } from "../../../../St/Reviews/cStyle";

import { colors, Container, makeStyles, Typography } from "@material-ui/core";
import { useBody } from "../../invCompany/style";
import { txtA, txtE } from "../../../../Label/Lbl";
import  InvTotal  from "../../AFReadyDisigen/A4/InvTotal";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
const BodyInv = (props) => {
  const cls = useRptAF();
//   const { onUnmountComplete, data } = props;
  const { onUnmountComplete, data ,isFullPage,isLast,sum} = props;

 
  const clas = useInvAF();
  const calssBody = useBody();
//   const islast=(data?.length )

  const cellS = {

    padding: "0px 0px",
    textAlign: "-webkit-center",
    margin: "0px",
    whiteSpace: "normal",
    border: "solid 0.1px",
    fontSize: "0.7rem",
    width: "300px",
    fontFamily: "Amiri-Regular",
    fontWeight:"bold",
  };
  var columns2 = [
   // { title: "م", field: "id" },
    { title:"Model \n Model", field: "barcode" },
    { title: "Item Name \n اسم الصنف", field: "symbol", cellStyle: cellS },
    {
      title: "Price \n سعر الوحدة",
      field: "new_exchange_price",
      
    },
    { title: "Qoantity \n الكمية ", field: "val1" },
    // { title: txtA.taxableAmount, field: "totBill" },
    { title:"BeforeVat"+"\n"+ "الخاضع للضريبة", field: "totBill" },

    { title:"Discount \n الخصم", field: "discount" },
    // { title: txtA.taxRate, field: "taxperc" },
    { title:"VatAmount \n مبلغ الضريبة", field: "taxval" },
    { title: "NetAmount \n شامل الضريبة", field: "tot_net_curr" },
  ];
  
  var columns = [
    { title: "No \n م", field: "id" },
     // { title:premiApp.billTemplate !== 9?"Barcode \n الباركود":"No Item \n الرقم", field: "barcode" },
     { title: "Item Name \n اسم الصنف", field: "symbol", cellStyle: cellS },
     { title: "Unit \n الوحدة", field: "unit_name", },
     { title: "Qoantity \n الكمية ", field: "val1" },
     {
       title: "Price \n السعر ",
       field: "new_exchange_price",
       
     },
     // { title: txtA.taxableAmount, field: "totBill" },
 
     { title:"Discount \n الخصم", field: "discount" },
     { title:"BeforeVat"+"\n"+ "الخاضع للضريبة", field: "totBill" },
 
     // { title: txtA.taxRate, field: "taxperc" },
     { title:"VatAmount \n مبلغ الضريبة", field: "taxval" },
     { title: "NetAmount \n شامل الضريبة", field: "tot_net_curr" },
   ];
  const data3 = data.map((row, i) => {
    return { ...row, id: i + 1 };
  });
  const data22 = data.map((row,i) => {
    return {
      ...row,
     id: i + 1,
      totBill: row.totBill.toFixed(2),
      new_exchange_price: row.new_exchange_price.toFixed(2),
      tot_net_curr: row.tot_net_curr.toFixed(2),
      discount: row.discount.toFixed(2),
    };
  });
  const data2 = data.map((row,i) => {
    return {
      ...row,
     id: i+1,
      totBill: row.new_exchange_price==row.exchange_price? row.totBill.toFixed(2) 
      :(row.tot_net_curr - (row.tot_net_curr / ((100 + row.taxperc) / row.taxperc))).toFixed(2),
      new_exchange_price: row.new_exchange_price==row.exchange_price? row.new_exchange_price.toFixed(2)
      :(row.new_exchange_price - (row.new_exchange_price / ((100 + row.taxperc) / row.taxperc))).toFixed(4),
      tot_net_curr: row.tot_net_curr.toFixed(2),
      discount: row.discount.toFixed(2),

    };
  });
 
  // return (
  //   <Container>
  //     <Grid
  //       item
  //       container
  //       // className={clas.Bdyroot}
  //       style={{ direction: "rtl" ,marginTop:"0.4rem",marginLeft:"0rem",marginRight:"0rem"}}
  //     >
  //       <Grid item container>
  //         <Grid item xs={12} 
  //         // className={calssBody.BdymtTablMu}
  //          style={{ direction: "rtl" ,margin:"0rem"}}
  //         >
  //           <MaterialTable
  //             style={{ boxShadow: "none" }}
  //             //style={{direction:'ltr'}}
  //             options={{
  //               showTitle: false,
  //               toolbar: false,
  //               sorting: false,
  //               selection: false,
  //               // ToolBar Search
  //               searchFieldAlignment: "left",
  //               searchFieldVariant: "outlined",
  //               // ToolBar header
  //               showSelectAllCheckbox: false,
  //               showTextRowsSelected: false,
  //               // body
  //               maxBodyHeight: "24rem",
  //               minBodyHeight: "24rem",
  //               // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
  //               // footer
  //               pageSize: 5,
  //               paginationType: "stepped",
  //               showFirstLastPageButtons: false,

  //               paging: false,
  //               headerStyle: {
  //                 ...stElemnt.AF.header,
  //                 backgroundColor: colors.grey[100],
  //                 fontWeight: "bold",
  //                 paddingTop: "2px",
  //                 paddingBottom:"2px",
  //                 border: "solid 0px",
  //                 border: "solid 0.1px",
                 
                  
  //               },
  //               cellStyle: { ...stElemnt.AF.cell, fontWeight: "bold" },
  //             }}
  //             localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
  //             columns={columns.map(column=>({
  //               ...column,
  //               title:column.title.split("\n").map((line,index)=>(
  //                 <div key={index}>{line}</div>
  //               )),
               
  //             }))}
  //             data={data2}
  //           />
             
  //         </Grid>
  //         {islast && <InvTotal/>}
  //       </Grid>

  //     </Grid>
  //   </Container>
  // );


  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container //className={cls.rootBdyup}
      style={{paddingTop:"0.5rem" ,direction: "rtl",border: "dotted 1px #393939",
        }}>
        <Grid
          item
          xs={12}
          style={{
          //  maxHeight: isFullPage ? sum >1 ? isLast?  "38em" :"40em" :"38em" :"38em" ,
           // minHeight: isFullPage ? sum >1 ? isLast?  "35em" :"40em" :"38em" :"38em" ,
              maxHeight:"31rem",
            minHeight:"31rem",
           
          }}
        >
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              headerStyle: {
                ...stElemnt.AF.header,
                backgroundColor: colors.grey[100],
                fontWeight: "bold",
                paddingTop: "2px",
                paddingBottom:"2px",
                border: "solid 0px",
                border: "solid 0.1px",
               
                
              },
              paging: false,
             // headerStyle: { ...stElemnt.AF.header, backgroundColor: "grey" },
              cellStyle: {
                ...stElemnt.AF.cell,
                padding: "0px 1px",
                // fontSize: 8,
              },
              // maxBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
              // minBodyHeight: isFullPage ? "56.3rem" : "35.6rem",
            }}
            localization={{ body: { emptyDataSourceMessage: <h1></h1> } }}
            columns={columns.map(column=>({
              ...column,
              title:column.title.split("\n").map((line,index)=>(
                <div key={index}>{line}</div>
              )),
             
            }))}
            data={data2}
          />
          {isLast && <InvTotal/>}
        </Grid>
        {/* <Grid item xs={12}>
        </Grid> */}
      </Grid>
      
    </Grid>
  );
};

export default BodyInv;
